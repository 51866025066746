import { render, staticRenderFns } from "./ui-table.vue?vue&type=template&id=854b32e0"
import script from "./ui-table.vue?vue&type=script&lang=js"
export * from "./ui-table.vue?vue&type=script&lang=js"
import style0 from "./ui-table.vue?vue&type=style&index=0&id=854b32e0&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.8_css-loader@6.11.0_webpack@5.94.0__lodash@4.17.21_v_4m2eslrdamwwvgdquzj2sgy7aa/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("F:\\dev\\gmd-top-platform-web@dev\\node_modules\\.pnpm\\vue-hot-reload-api@2.3.4\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('854b32e0')) {
      api.createRecord('854b32e0', component.options)
    } else {
      api.reload('854b32e0', component.options)
    }
    module.hot.accept("./ui-table.vue?vue&type=template&id=854b32e0", function () {
      api.rerender('854b32e0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ui/ui-table.vue"
export default component.exports