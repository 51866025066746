// import VConsole from 'vconsole'
// const vConsole = new VConsole()
// or init with options
// const vConsole = new VConsole({ theme: 'dark' })

import Vue from 'vue'
import setIP from '@/utils/setIP'
import './login.persistence'
import * as constant from '@/constant'
import { LoginPage } from '@/constant'
import App from './App.vue'
import router from './router'
import store from './store'
import * as lodash from 'lodash'
import { removeToken, setToken, getToken } from '@/utils/auth'
import 'element-ui/lib/theme-chalk/index.css'
import 'normalize.css'
import './icons'
import '@/permission'
import * as echarts from 'echarts'
import ECharts from 'vue-echarts'
import '@/assets/styles/base.scss'
import '@/assets/styles/common.scss'
import '@/assets/iconfont/iconfont.css'
import Pagination from '@/components/pagination'
import Storage from '@/utils/storage.js'
import ElementUI from 'element-ui'
import { MessageBox } from 'element-ui'
import Ui from '@/components/ui'
import { selfInfo } from './api'

Vue.use(ElementUI)
Vue.use(Ui)
Vue.component('Pagination', Pagination)
Vue.use(Storage)
Vue.component('v-chart', ECharts)
Vue.config.productionTip = false
Vue.prototype.MessageBox = MessageBox
Vue.prototype.$socket = null
Vue.prototype.$instance = {}
Vue.prototype.$echarts = echarts
Vue.prototype.$setToken = setToken
Vue.prototype.$copy = function (obj) {
  return JSON.parse(JSON.stringify(obj))
}
Vue.prototype.$sum = function (arr) {
  return arr.reduce(function (prev, curr, idx, arr) {
    return prev + curr
  })
}
Vue.prototype.isArray = function (obj) {
  return Array.isArray(obj)
}
Vue.prototype.Math = Math
Vue.prototype._ = lodash
Vue.prototype.$wait = function (time = 0, resolver) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(resolver)
    }, time)
  })
}
Vue.prototype.$randomSortArr = function (arr) {
  var len = arr.length
  for (let i = len - 1; i >= 0; i--) {
    var randomIndex = Math.floor(Math.random() * (i + 1))
    var temp = arr[randomIndex]
    arr[randomIndex] = arr[i]
    arr[i] = temp
  }
  return arr
}
Object.defineProperty(Vue.prototype, 'userId', {
  get: function () {
    return window.$app ? window.$app.$store.state.user.userInfo.userId + '' : ''
  },
})
Object.defineProperty(Vue.prototype, 'uid', {
  get: function () {
    return window.$app ? window.$app.$store.state.user.userInfo.userId + '' : ''
  },
})
Object.defineProperty(Vue.prototype, '$videoEle', {
  get: function () {
    return window.$app ? window.$app.$children[0].$refs.video : null
  },
})
Object.defineProperty(Vue.prototype, 'companyInfo', {
  get: function () {
    return window.$app ? window.$app.$store.state.user.userInfo.companyInfo : {}
  },
})
Object.defineProperty(Vue.prototype, 'userInfo', {
  get: function () {
    return window.$app ? window.$app.$store.state.user.userInfo : {}
  },
})
Object.defineProperty(Vue.prototype, 'usersMap', {
  get: function () {
    return window.$app ? window.$app.$store.state.user.map : {}
  },
})
Vue.prototype.$console = function () {
  return true
}
Vue.prototype.loginInvalid = function (from) {
  if (sessionStorage.VUE_APP_LOGIN_PERSISTENCE) {
    window.$loginPersistenceUtils.tokenClear().then(() => {
      window.$loginPersistenceUtils.toLogin().then(() => {
        location.reload()
      })
    })
    return
  }
  window.console.debug('loginInvalid from', from, this.$route.name)
  if ($app.$store.state.isLogingOut) {
    return
  } else if (this.$route.name === LoginPage) {
    return
  }
  $app.$store.state.isLogingOut = true
  $app.MessageBox.alert('登入已失效，是否重新登录', {
    confirmButtonText: '重新登录',
    type: 'warning',
    showClose: false,
    closeOnClickModal: false,
  })
    .then(() => {
      $app.logout()
    })
    .catch(() => {
      $app.logout()
    })
}
Vue.prototype.$libs = {
  removeToken: removeToken,
  webSpeech: new (require('@/utils/webSpeech').default)(),
}
Vue.prototype.logout = function () {
  removeToken()
  $app.$store.state.user.userInfo = {}
  if ($app.$route.name !== LoginPage) {
    $app.$router.push({
      name: LoginPage,
      query: { redirect: $app.$route.path },
    })
  }
}
Vue.prototype.$const = constant
Vue.prototype.$public = function (...args) {
  // const eventName = args[0]
  // window.console.debug('事件名称：' + eventName)
  $app.$emit.apply($app, args)
}
Vue.prototype.gcj02towgs84 = gcj02towgs84
let userResolve
Vue.prototype.userReady = new Promise(resolve => {
  userResolve = resolve
})
Vue.prototype.isLocalhost = location.hostname === 'localhost'
Vue.prototype.avatarHandler = function (avatar) {
  let src = ''
  if (avatar) {
    if (avatar.startsWith('/files/')) {
      src = `${$app.VUE_APP_BASE_API}/api` + avatar.replace('files', 'media')
    } else if (avatar.startsWith('/media/')) {
      src = `${$app.VUE_APP_BASE_API}/api` + avatar
    } else if (avatar.startsWith('//') || avatar.startsWith('http')) {
      src = avatar
    } else if (avatar.startsWith('http')) {
      src = avatar
    }
  }
  if (!src) {
    src = '/avatar.png'
  }
  return src
}
const defers = {
  maptalks: {
    promise: null,
    resolve: null,
  },
}
defers.maptalks.promise = new Promise(r => {
  defers.maptalks.resolve = r
})
Vue.prototype.$defers = defers

function _userResolve (data) {
  $app.$store.state.user.lastUser = data
  $app.$store.state.user.userInfo = data
  $app.$store.state.user.map[data.uid] = data
  userResolve(data)
}

Vue.prototype.userFetch = function () {
  const data = $app.$storage.getJson('api_v3_account_selfInfo')
  if (data) {
    _userResolve(data)
    return
  }
  selfInfo()
    .then(data => {
      if (data && data.id) {
        data.userId = data.id
        data.uid = data.id
        data.avatar = $app.avatarHandler(data.avatar)
        // 秒 24h = 86400 秒
        $app.$storage.setJson('api_v3_account_selfInfo', data, 86400)
        _userResolve(data)
      } else {
        $app.logout()
      }
    })
    .catch(() => {
      $app.logout()
    })
}

async function start () {
  const loaders = []

  loaders.push(
    new Promise(resolve => {
      window.addEventListener('load', () => {
        resolve()
      })
    }),
    setIP(Vue),
  )

  if (sessionStorage.VUE_APP_LOGIN_PERSISTENCE) {
    loaders.push(getToken())
  }

  await Promise.all(loaders)
  setupApp()
}

function setupApp () {
  window.$app = new Vue({
    router,
    store,
    el: '#app',
    render: h => h(App),
    mixins: [require('./main.mixin').default],
  })
}

start()
